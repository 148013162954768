



















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CellEditType } from '@/models/core/base';
import { deepCopy } from '@/util/util';

@Component({})
export default class EditableCell extends Vue {
  @Prop({ required: true }) value: any;
  @Prop({
    default: () => {
      return { type: CellEditType.STRING };
    },
  })
  editProperties: {
    type: CellEditType;
    min?: number;
    max?: number;
    step?: number;
    digits?: number;
    maxlength?: number;
    width?: number;
  };

  cellValue = null;
  originalCellValue = '';
  editMode = false;
  CellEditType = CellEditType;
  $refs: {
    stringInput;
    numberInput;
  };

  mounted() {
    if (this.value !== undefined && this.value !== null) {
      this.cellValue = this.value;
      this.originalCellValue = deepCopy(this.value);
    }
  }

  @Watch('value')
  cellValueChanged() {
    this.cellValue = this.value;
    this.originalCellValue = deepCopy(this.value);
    this.editMode = false;
  }

  editCell() {
    this.editMode = true;
    if (this.editProperties.type === CellEditType.NUMBER) {
      this.$nextTick(() => {
        this.$refs.numberInput.focus();
      });
    } else if (this.editProperties.type === CellEditType.STRING) {
      this.$nextTick(() => {
        this.$refs.stringInput.focus();
      });
    }
  }

  discardCell() {
    if (this.editProperties.type === CellEditType.DATE) {
      this.cellValue = new Date(deepCopy(this.originalCellValue));
    } else {
      this.cellValue = deepCopy(this.originalCellValue);
    }
    this.editMode = false;
  }

  saveCell() {
    this.editMode = false;
    this.originalCellValue = deepCopy(this.cellValue);
    this.$emit('save', this.cellValue);
  }

  formatNumber(n: string): string {
    return parseFloat(n).toFixed(this.editProperties.digits || 0);
  }
}
