

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ObjectBreadcrumbsButton from '@/components/common/forms/ObjectBreadcrumbsButton.vue';
import { TransientBaseObject, ModelClass } from '@/models/core/base';

@Component({
  components: {
    ObjectBreadcrumbsButton,
  },
})
export default class ObjectBreadcrumbsV2 extends Vue {
  @Prop({ required: true }) modelClass: ModelClass;
  @Prop({ required: true }) dataObject: TransientBaseObject;

  get ancestors() {
    return this.modelClass.ancestors || [];
  }

  get breadcrumbs() {
    return this.ancestors
      .filter(ancestor => {
        return ancestor.mainParent === true;
      })
      .map(ancestor => {
        let id = '';
        if (
          ancestor.relatedProperty &&
          this.dataObject &&
          this.dataObject[ancestor.relatedProperty]
        ) {
          id = this.dataObject[ancestor.relatedProperty];
        } else if (
          this.dataObject &&
          ancestor.modelClass.objectType in this.dataObject
        ) {
          id = this.dataObject[ancestor.modelClass.objectType];
        }
        return {
          modelClass: ancestor.modelClass,
          id,
        };
      });
  }
}
