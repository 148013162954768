



















import Vue from 'vue';
import Component from 'vue-class-component';
import ParentSelectorV2 from '@/components/common/lists/ParentSelectorV2.vue';
import { ModelClass } from '@/models/core/base';

@Component({
  props: {
    modelClass: {
      required: true,
    },
    isForm: {
      default: false,
    },
    filter: {
      required: false,
    },
  },
  components: {
    ParentSelectorV2,
  },
})
export default class ParentSelectorsV2 extends Vue {
  modelClass: ModelClass;

  get ancestors() {
    return (
      this.modelClass.ancestors.filter(
        ancestor => ancestor.showListFilter !== false,
      ) || []
    );
  }
}
