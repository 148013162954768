











































































import axios from 'axios';
import BaseForm from '@/components/common/BaseForm.vue';
import store from '@/store/index';
import { PasswordReset, PasswordResetRequest } from '@/models/core/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { authStore } from '@/store/modules/auth/auth';

@Component({
  name: 'reset_password',
  components: {
    'base-form': BaseForm,
  },
  data() {
    return {
      passwordResetRequest: {
        username: '',
        email: '',
        password1: '',
        password2: '',
      },
      passwordReset: {
        username: '',
        nonce: '',
        pin: '',
      },
    };
  },
})
export default class ResetPassword extends Vue {
  $refs: {
    baseForm: BaseForm;
  };
  errorMessage = '';
  passwordResetRequest: PasswordResetRequest;
  passwordReset: PasswordReset;

  get activation() {
    return this.passwordReset.nonce !== '';
  }

  async mounted() {
    try {
      await authStore.logOut();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  enterKey() {
    if (!this.activation) {
      this.requestResetPassword();
    } else {
      this.resetPassword();
    }
  }

  requestResetPassword() {
    const loadingComponent = this.$buefy.loading.open({});
    this.$api
      .requestResetPassword(this.passwordResetRequest)
      .then(response => {
        loadingComponent.close();
        this.$refs.baseForm.clearError();
        this.passwordReset.nonce = response.data.nonce;
        this.passwordReset.id = response.data.profile;
      })
      .catch(error => {
        loadingComponent.close();
        this.$refs.baseForm.handleError(error);
      });
  }

  resetPassword() {
    const loadingComponent = this.$buefy.loading.open({});
    this.$api
      .resetPassword(this.passwordReset)
      .then(response => {
        loadingComponent.close();
        this.$router.push({ name: 'login' });
      })
      .catch(error => {
        loadingComponent.close();
        this.passwordReset.nonce = '';
        this.passwordReset.pin = '';
        this.$refs.baseForm.handleError(error);
      });
  }

  get passwordsMatch() {
    if (
      this.passwordResetRequest.password1 ===
      this.passwordResetRequest.password2
    ) {
      return true;
    } else {
      if (
        this.passwordResetRequest.password1 !== '' &&
        this.passwordResetRequest.password2 !== ''
      ) {
        this.errorMessage = this.$tc('auth.passwordNoMatch');
        return false;
      } else {
        return true;
      }
    }
  }
}
