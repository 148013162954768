















































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import {
  contentTypes,
  OBJECT_AUTHORIZATION_DEFAULT,
} from '@/models/core/defaults';
import { Group, Role } from '@/models/core/models';
import { ObjectAuthorization } from '@/models/core/models';
import { GlobalState } from '@/store/GlobalStore';
import { deepCopy } from '@/util/util';
import { Context } from '@/api/ApiClientV2';
import { getModelClass } from '@/models/objectRegistry';

@Component({
  components: {
    BaseForm,
  },
  mixins: [BeforeLeaveGuard],
})
export default class ObjectAuthorizationForm extends Vue {
  @Prop({ required: true }) id: string;

  groups: Group[] = [];
  roles: Role[] = [];
  objectList: any[] = [];
  objectAuthorization: ObjectAuthorization = deepCopy(
    OBJECT_AUTHORIZATION_DEFAULT,
  );
  selected = { id: '' };
  key = '';
  manualMode = false;
  contentTypes = contentTypes;

  get rolePlaceholder() {
    if (this.roles.length < 1) {
      return 'No roles available.';
    } else {
      return 'Select role.';
    }
  }

  get groupPlaceholder() {
    if (this.groups.length < 1) {
      return 'No groups available.';
    } else {
      return 'Select group.';
    }
  }

  get authorizedObjects() {
    return this.objectList.filter(obj => {
      return obj._permissions.authorize;
    });
  }

  get transformedObjectAuthorization() {
    if (this.id === '0') {
      this.objectAuthorization.object_id = this.selected.id;
      this.objectAuthorization.organisation =
        this.$store.getters['global/organisation'].id;
    }
    return this.objectAuthorization;
  }

  async mounted() {
    const loading = this.$buefy.loading.open({});
    try {
      const context: Context = {
        filter: {
          organisation: this.$store.getters['global/organisation'].id,
        },
        pagination: {
          page: 1,
          pageSize: 50,
        },
      };
      this.groups = await this.$apiv2.getListItems<Group>(Group, context);
      this.roles = await this.$apiv2.getListItems<Role>(Role, context);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  switchManualMode() {
    this.manualMode = !this.manualMode;
  }

  async onContentTypeChange(value) {
    const loading = this.$buefy.loading.open({});
    try {
      const contentType = contentTypes.find(c => c.name === value);
      this.key = contentType.key;
      if (contentType) {
        const context: Context = {
          filter: {
            organisation: this.$store.getters['global/organisation'].id,
          },
          pagination: {
            page: 1,
            pageSize: 50,
          },
        };
        let filterOK = true;
        if (contentType.name === 'client.clientappsettingkind') {
          context.filter['client_app'] =
            this.$store.getters['global/selectedClientApp']?.id;
        } else if (contentType.name === 'device.devicesettingkind') {
          context.filter['model'] = this.$store.getters['global/model']?.id;
          if (!context.filter['model']) {
            filterOK = false;
          }
        }
        if (filterOK) {
          this.objectList = await this.$apiv2.getListItems(
            contentType.modelClass,
            context,
          );
        } else {
          this.objectList = [];
        }
      }
    } catch (error) {
      this.objectList = [];
      this.$errorHandler.handleError(error);
    }
    this.selected = { id: '' };
    loading.close();
  }
}
