










































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import LanguageSwitcher from './LanguageSwitcher.vue';
import NavLinks from './NavLinks.vue';

import { adminRouteName } from '@/apps/admin/app';
import { DMS_FRONTEND_THEME, DMS_LOCALE_SWITCHER } from '@/env';
import { authStore } from '@/store/modules/auth/auth';
import { Profile } from '@/models/core/profile';

@Component({
  name: 'navigation-bar',
  components: {
    LanguageSwitcher,
    NavLinks,
  },
  computed: {
    ...mapGetters('global', ['navbarHidden']),
  },
})
export default class NavigationBar extends Vue {
  refreshing = false;
  theme = DMS_FRONTEND_THEME;

  get profileRouteName(): string {
    return adminRouteName('profile-detail');
  }

  get menuButtonClass(): string {
    let cssClass = 'button is-white menu-button';
    // hide menu button on desktop unless
    // client menu is hidden or
    // reader mode is disabled (no need for close button)
    if (
      !this.$store.getters['global/hideClientAppsMenu'] ||
      !this.$store.getters['global/readerModeEnabled']
    ) {
      cssClass += ' is-hidden-desktop is-hidden-tablet';
    }
    return cssClass;
  }

  get isPushed() {
    return (
      !this.$store.getters['global/navigationIsCollapsed'] &&
      this.$route.params.app_handle !== undefined &&
      this.isLoggedIn
    );
  }

  get readerModeEnabled() {
    return !this.$store.getters['global/readerModeEnabled'];
  }

  get profile(): Profile | null {
    return authStore.profile;
  }

  get displayName(): string {
    if (this.profile?.first_name || this.profile?.last_name) {
      return this.profile.first_name + ' ' + this.profile.last_name;
    } else {
      return this.profile?.username ?? '';
    }
  }

  get isLoggedIn(): boolean {
    return authStore.isLoggedIn;
  }

  get showTime(): boolean {
    return false;
  }

  get showRefresh(): boolean {
    return true;
  }

  get showLanguageSwitcher(): boolean {
    return DMS_LOCALE_SWITCHER;
  }

  async logout() {
    try {
      await authStore.logOut();
      this.$router.push('/login');
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  getAvatar() {
    if (this.profile?.avatar) {
      return this.$api.getAttachmentUrl(this.profile.avatar);
    } else {
      return '';
    }
  }

  refreshData() {
    if (this.refreshing) {
      return;
    }
    this.refreshing = true;
    // TODO: remove apiv1 once it is not used anymore
    Promise.all([this.$apiv2.refreshData(), this.$api.refreshData()])
      .then(() => {
        this.refreshing = false;
        this.$buefy.toast.open({
          message: this.$tc('common.refreshSuccess'),
          type: 'is-success',
        });
      })
      .catch(error => {
        console.log(error);
        this.refreshing = false;
        this.$buefy.toast.open({
          message: 'Error refreshing!',
          type: 'is-danger',
        });
      });
  }

  switchReaderMode() {
    this.$store.commit('global/setReaderMode', {
      value: this.readerModeEnabled,
    });
    if (this.readerModeEnabled) {
      this.$store.commit('global/setNavigationCollapsed', { value: false });
    }
  }
}
