import { render, staticRenderFns } from "./DeliveryProcedureForm.vue?vue&type=template&id=6580a8ec&scoped=true&"
import script from "./DeliveryProcedureForm.vue?vue&type=script&lang=ts&"
export * from "./DeliveryProcedureForm.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryProcedureForm.vue?vue&type=style&index=0&id=6580a8ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6580a8ec",
  null
  
)

export default component.exports