
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { DEVICE_SETTING_DEFAULT } from '@/models/device/defaults';
import SettingForm from '@/apps/device/components/SettingForm.vue';
import {
  DeviceSetting,
  DeviceSettingKind,
  Device,
} from '@/models/device/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Context } from '@/api/ApiClientV2';
import { deepCopy } from '@/util/util';
import ApplySetting from './ApplySetting.vue';

@Component({
  components: {
    SettingForm,
    ApplySetting,
  },
})
export default class DeviceSettings extends Vue {
  @Prop({ required: true }) device!: string;
  @Prop({ required: true }) model!: string;
  @Prop({ required: true }) appId!: string;
  @Prop({ required: true }) role!: string;
  @Prop({ required: true }) mode!: 'device';

  settings: DeviceSetting[] = [];
  settingKinds: DeviceSettingKind[] = [];
  destroySubject = new Subject<void>();
  settingToApply: DeviceSetting | null = null;

  mounted(): void {
    this.getSettings();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getSettings();
      });
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onSettingSaved(): void {
    this.$apiv2.refreshData();
  }

  async getSettings(): Promise<void> {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      this.settingKinds = await this.getSettingKinds();
      this.settings = await Device.getSettings(this.$apiv2, this.device);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  async onDeleteSetting(setting: DeviceSetting): Promise<void> {
    const key = setting.key;
    const id = setting.id;
    if (id === '0') {
      this.getSettings();
      return;
    }
    const loadingComponent = this.$buefy.loading.open({});
    try {
      await Device.deleteSetting(this.$apiv2, this.device, key);
      await this.getSettings();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  addSetting(): void {
    this.settings.push(deepCopy(DEVICE_SETTING_DEFAULT));
  }

  async getSettingKinds(): Promise<DeviceSettingKind[]> {
    if (this.model) {
      try {
        const context: Context = {
          filter: {
            model: this.model,
          },
          pagination: {},
        };
        return await this.$apiv2.getListItems<DeviceSettingKind>(
          DeviceSettingKind,
          context,
        );
      } catch (error) {
        this.$errorHandler.handleError(error);
        return Promise.resolve([]);
      }
    } else {
      return Promise.resolve([]);
    }
  }

  onApplyToOtherDevices(setting: DeviceSetting): void {
    this.settingToApply = setting;
  }
}
