


























import { Component, Vue } from 'vue-property-decorator';
import { getModelClass } from '@/models/objectRegistry';
import { sortJsonObject } from '@/util/util';

@Component({
  name: 'formly_json-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class JsonField extends Vue {
  jsonString = '';
  errorMessage = '';

  mounted() {
    if (this.$props.model[this.$props.field.key]) {
      let object = this.$props.model[this.$props.field.key];
      if (this.$props.field.modelClass) {
        const modelClass = getModelClass(this.$props.field.modelClass);
        if (modelClass.transformJsonObject)
          // transform object if function is defined on modelclass
          try {
            object = modelClass.transformJsonObject(object);
          } catch (error) {
            this.$buefy.toast.open({
              message: this.$errorHandler.errorToString(error),
              type: 'is-danger',
              duration: 5000,
            });
          }
      }
      const sortedObject = sortJsonObject(object);
      this.jsonString = JSON.stringify(sortedObject, null, 2);
    }
  }

  validateJSON() {
    try {
      this.$props.model[this.$props.field.key] = JSON.parse(this.jsonString);
      this.errorMessage = '';
    } catch (err) {
      this.errorMessage = this.$errorHandler.errorToString(err);
    }
  }

  get label() {
    return this.$props.field.templateOptions.wrapper.properties.label;
  }
}
