














import Vue from 'vue';
import Component from 'vue-class-component';
import { DeliveryProcedure } from '@/models/firmware/models';

@Component({})
export default class DeliveryProcedureList extends Vue {
  DeliveryProcedure = DeliveryProcedure;
}
