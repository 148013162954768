





























































































































































import DependencyTreeItem from './DependencyTreeItem.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'dependency-tree',
  components: {
    'dependency-tree-item': DependencyTreeItem,
  },
  props: {
    id: {
      required: true,
    },
    role: {
      required: true,
    },
    elements: {
      required: true,
    },
    propagate: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class DependencyTree extends Vue {
  enableAll(value = true) {
    Object.keys(this.$props.role.permissions).forEach(contentType => {
      Object.keys(this.$props.role.permissions[contentType]).forEach(
        permission => {
          this.$props.role.permissions[contentType][permission] = value;
        },
      );
    });
  }

  disableAll() {
    this.enableAll(false);
  }
}
