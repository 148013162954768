










import Vue from 'vue';
import Component from 'vue-class-component';
import { DeviceSettingKind } from '@/models/device/models';

@Component({})
export default class DeviceSettingKindList extends Vue {
  DeviceSettingKind = DeviceSettingKind;
}
