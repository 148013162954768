













import Vue from 'vue';
import Component from 'vue-class-component';
import { Model } from '@/models/device/models';

@Component({})
export default class ModelList extends Vue {
  Model = Model;
  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    };
  }
}
