












import Vue from 'vue';
import Component from 'vue-class-component';
import { Channel } from '@/models/firmware/models';

@Component({})
export default class ChannelList extends Vue {
  Channel = Channel;
}
