

























































































































































































































import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-patch-firmware-image',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class PatchFirmwareImage extends Vue {
  plugin: DeliveryProcedurePlugin;
  newInstructionType = 'copy';
  instructionTypes = [
    {
      name: 'copy',
      prettyName: 'Copy',
    },
    {
      name: 'patch',
      prettyName: 'Patch',
    },
    {
      name: 'patch-crc16',
      prettyName: 'Patch CRC16',
    },
  ];

  mounted() {
    this.plugin = this.$props.plugin;
  }

  addInstruction(type) {
    let newInstruction = {};
    if (type === 'copy') {
      newInstruction = {
        i: type,
        src: '',
        regions: [],
      };
    } else if (type === 'patch') {
      newInstruction = {
        'i': type,
        'src': '',
        'src-ofs': '',
        'regions': [],
      };
    } else if (type === 'patch-crc16') {
      newInstruction = {
        'i': type,
        'regions': [],
        'src-regions': [],
      };
    } else {
      console.log('Unknoown type.');
      return;
    }
    this.plugin.config['instructions'].push(newInstruction);
  }

  removeInstruction(index) {
    this.plugin.config['instructions'].splice(index, 1);
  }

  getLabel(index, type) {
    if (index === 0) {
      if (type === 0) {
        return 'Input payload';
      } else if (type === 1) {
        return 'Remove';
      } else if (type === 2) {
        return 'Start';
      } else if (type === 3) {
        return 'End';
      } else if (type === 4) {
        return 'Length';
      }
    }
  }

  addPayload() {
    this.plugin.config['input_payloads'].push('');
  }

  removePayload(index) {
    this.plugin.config['input_payloads'].splice(index, 1);
  }

  addRegion(instruction, region) {
    instruction[region].push({
      start: '',
      end: '',
      len: '',
    });
  }

  removeRegion(instruction, index) {
    instruction.regions.splice(index, 1);
  }
}
