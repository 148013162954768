












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Model } from '@/models/device/models';
import {
  Channel,
  DeliveryMethod,
  DeliveryProcedure,
  Firmware,
} from '@/models/firmware/models';

@Component({
  props: {
    payload: {
      required: true,
    },
  },
  data() {
    return {
      modelName: '',
      currentChannelName: '',
      targetChannelName: '',
      deliveryMethodName: '',
      deliveryProcedureName: '',
      currentFirmwareVersion: '',
      targetFirmwareVersion: '',
    };
  },
})
export default class UpdateRequest extends Vue {
  modelName: string;
  currentChannelName: string;
  targetChannelName: string;
  deliveryMethodName: string;
  deliveryProcedureName: string;
  currentFirmwareVersion: string;
  targetFirmwareVersion: string;

  async mounted() {
    const fields: [any, string][] = [
      [Model, 'model'],
      [Channel, 'current_channel'],
      [Channel, 'target_channel'],
      [DeliveryMethod, 'delivery_method'],
      [DeliveryProcedure, 'delivery_procedure'],
      [Firmware, 'current_firmware'],
      [Firmware, 'target_firmware'],
    ];

    const result = await Promise.all(
      fields.map(field => {
        if (
          this.$props.payload &&
          this.$props.payload.fields &&
          this.$props.payload.fields[field[1]]
        ) {
          return this.$apiv2
            .get<any>(field[0], this.$props.payload.fields[field[1]])
            .catch(err => {
              console.log(this.$errorHandler.errorToString(err));
            });
        } else {
          return Promise.resolve();
        }
      }),
    );

    this.modelName = result[0] ? result[0].name : 'n/a';
    this.currentChannelName = result[1] ? result[1].name : 'n/a';
    this.targetChannelName = result[2] ? result[2].name : 'n/a';
    this.deliveryMethodName = result[3] ? result[3].name : 'n/a';
    this.deliveryProcedureName = result[4] ? result[4].name : 'n/a';
    this.currentFirmwareVersion = result[5] ? result[5].version : 'n/a';
    this.targetFirmwareVersion = result[6] ? result[6].version : 'n/a';
  }
}
