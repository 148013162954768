





























































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BaseListCell from './BaseListCell.vue';
import ObjectStateTag from '../common/ObjectStateTag.vue';
import {
  BaseObject,
  LifeCycleState,
  BaseListEventType,
  BaseListColumn,
  ModelClass,
} from '@/models/core/base';
import { getClientAppOfRoute } from '@/apps/routingUtils';
import { clientAppRouteName } from '@/apps/clientAppRegistry';
import { copyTextToClipboard } from '@/util/clipboard';
import { Context } from '@/api/ApiClientV2';
import MultilineTooltip from './MultilineTooltip.vue';
import { CustomAction } from '@/components/common/interfaces';
import { RawLocation } from 'vue-router';
import { has } from '@/util/util';
import { ApiListSubscription } from '@/api/ApiListSubscription';

@Component({
  components: {
    ObjectStateTag,
    BaseListCell,
    MultilineTooltip,
  },
})
export default class BaseListTable<T> extends Vue {
  @Prop({ required: true }) modelClass: ModelClass;
  @Prop({ required: true }) objectCollection: ApiListSubscription<T>;
  @Prop({ default: 'desc' }) defaultSortOrder: string;
  @Prop({ required: true }) columns: BaseListColumn[];
  @Prop({ default: false }) singleSelection: boolean;
  @Prop({ default: () => [] }) preSelectedRows: string[];
  @Prop({ default: () => [] }) addSelectedRows: string[];
  @Prop({ default: () => [] }) unselectableRows: string[];
  @Prop({ default: '' }) detailRouteName: string;
  @Prop({ default: null }) customDelete: (data: any) => Promise<any>;
  @Prop({ default: true }) hasActions: boolean;
  @Prop({ default: false }) canCopy: boolean;
  @Prop({ default: true }) canAdd: boolean;
  @Prop({ default: true }) canDelete: boolean;
  @Prop({ default: false }) canSelect: boolean;
  @Prop({ default: false }) canReorder: boolean;
  @Prop({ default: true }) canCopyData: boolean;
  @Prop({ default: () => [] }) customActions: CustomAction[];
  @Prop({ default: true }) hasDetailView: boolean;
  @Prop({ default: null }) customDetail: (data: any) => RawLocation;
  @Prop({ default: () => {} }) detailExtraParams: any;
  @Prop({ default: false }) isStriped: boolean;

  /**
   * The row selection is an object with IDs as keys and value:
   * - the row object, in case of single selection
   * - ID, in case of multi-selection
   * - undefined for unselected
   */
  rowSelections: { [id: string]: any | string | undefined } = {};
  delay = 400;
  clicks = 0;
  timer = null;
  hasPagination = false;

  mounted() {
    this.setPreselectedRows();
  }

  updated() {
    this.getHasPagination();
  }

  setPreselectedRows() {
    this.preSelectedRows.forEach((rowId: string) => {
      this.$set(this.rowSelections, rowId, rowId);
    });
  }

  clearSelectedRows() {
    this.rowSelections = {};
  }

  // when changing preSelectedRows, previously selected rows will be unselected
  @Watch('preSelectedRows')
  onPreselectedRowsChanged() {
    this.clearSelectedRows();
    this.setPreselectedRows();
  }

  // when changing addSelectedRows, previously selected rows will remain selected
  @Watch('addSelectedRows')
  onAddSelectedRowsChanged() {
    this.addSelectedRows.forEach((rowId: string) => {
      this.$set(this.rowSelections, rowId, rowId);
    });
  }

  @Watch('objectCollection.objects')
  collectionChanged() {
    this.setPreselectedRows();
  }

  get objectType() {
    return this.modelClass.objectType;
  }

  async callCustomActionCb(customAction, row) {
    try {
      await customAction.callback(row);
    } catch (err) {
      this.handleError(err);
    }
  }

  context(row) {
    return {
      [this.modelClass.objectType]: row.id,
      organisation: this.$store.getters['global/organisation'].id,
    };
  }

  getValue(index: number, row, column) {
    row = this.parseModel(row);
    if (Array.isArray(row[column.fieldName])) {
      return row[column.fieldName][index];
    } else {
      return row[column.fieldName];
    }
  }

  getDetailRouteName() {
    if (this.detailRouteName === '') {
      const clientApp = getClientAppOfRoute(this.$route);
      return clientApp
        ? clientAppRouteName(clientApp.view_id, this.objectType + '-detail')
        : '';
    }
    return this.detailRouteName;
  }

  getDetailLinkTo(row, copy = false) {
    if (this.customDetail !== null) {
      const detailView = this.customDetail(row);
      if (copy && typeof detailView !== 'string') {
        detailView.params = {
          ...detailView.params,
          id: '0',
          templateId: row.id,
        };
      }
      return detailView;
    }

    const extraQuery = this.modelClass.detailLinkQuery(this.context(row));
    return this.$routerHandler.getDetailLinkTo(
      this.getDetailRouteName(),
      row.id,
      copy,
      this.detailExtraParams,
      extraQuery,
    );
  }

  get initialSort() {
    if (this.objectCollection.filter && this.objectCollection.filter.order_by) {
      const match = this.objectCollection.filter.order_by.match(
        /^([a-zA-Z0-9_.]*?)(_dsc)?$/,
      );
      if (match.length >= 2) {
        let fieldName = match[1];
        const direction = match[2] ? 'desc' : 'asc';
        this.columns.some((column: BaseListColumn) => {
          if (column.sortFieldName === fieldName) {
            fieldName = column.fieldName;
            return true;
          }
          return false;
        });
        return [fieldName, direction];
      }
    }
    return [];
  }

  get hasNoContent() {
    return (
      this.objectCollection.status.fetching === false &&
      !Object.values(this.objectCollection.objects).length
    );
  }

  parseModel(model: any) {
    return this.modelClass.parseModel(model, this.modelClass.listFields);
  }

  isSelectable(rowId: string) {
    return !(this.unselectableRows.indexOf(rowId) > -1);
  }

  select(row: any): void {
    if (!this.isSelectable(row.id)) {
      this.$buefy.toast.open({
        message: 'Cannot select row',
        type: 'is-warning',
      });
      return;
    }
    if (this.rowSelections[row.id] !== undefined) {
      // unselect row
      this.$set(this.rowSelections, row.id, undefined);
      this.$emit('row-unselect', row.id);
    } else {
      // select row
      if (this.singleSelection) {
        for (const id in this.rowSelections) {
          if (this.rowSelections[id]) {
            this.$set(this.rowSelections, id, undefined);
          }
        }
        // single selection: emit row
        this.$set(this.rowSelections, row.id, row);
      } else {
        // no single selection: emid id
        this.$set(this.rowSelections, row.id, row.id);
      }
    }
    this.emitSelected();
  }

  emitSelected() {
    const selected = [];
    for (const id in this.rowSelections) {
      if (this.rowSelections[id] && this.rowSelections[id] !== undefined) {
        selected.push(this.rowSelections[id]);
      }
    }
    this.$emit('row-selection', selected);
  }

  onSelect(row) {
    if (this.canSelect) {
      this.select(row);
    } else if (this.hasDetailView) {
      this.$router.push(this.getDetailLinkTo(row));
    }
  }

  copy(row: any) {
    if (this.canCopy) {
      const copy = true;
      this.$router.push(this.getDetailLinkTo(row, copy));
    }
  }

  copyRowData(row: any) {
    this.clicks++;
    if (this.clicks === 1) {
      // We copy the ID already because Firefox does not allow this function inside the timeout handler
      copyTextToClipboard(row.id);
      this.timer = setTimeout(() => {
        this.$buefy.toast.open({
          message: 'Copied ID!',
          type: 'is-success',
        });
        this.clicks = 0;
      }, this.delay);
    } else {
      clearTimeout(this.timer);
      copyTextToClipboard(JSON.stringify(row, null, 2));
      this.$buefy.toast.open({
        message: 'Copied data!',
        type: 'is-success',
      });
      this.clicks = 0;
    }
  }

  async insertBefore(insert: BaseObject, before: BaseObject) {
    try {
      await this.modelClass.insertBefore(this, insert, before);
      this.$emit('base-list-event', BaseListEventType.REORDER);
      this.refreshCollection();
    } catch (error) {
      this.handleError(error);
    }
  }

  async moveUp(index) {
    const objects = this.objectCollection.objects;
    if (index === 0) {
      // top element of this page
      if (this.objectCollection.pagination.page === 1) {
        // first page -> move to end
        this.insertBefore(objects[index], null);
      } else {
        // not first page -> move one page up
        const context: Context = {
          filter: this.objectCollection.filter,
          pagination: { ...this.objectCollection.pagination },
        };
        context.pagination.page -= 1;
        const pageBefore = await this.$apiv2.getListItems<BaseObject>(
          this.modelClass,
          context,
        );
        this.insertBefore(
          objects[index],
          pageBefore[this.objectCollection.pagination.pageSize - 1],
        );
      }
    } else {
      // any other element on this page
      this.insertBefore(objects[index], objects[index - 1]);
    }
  }

  async moveDown(index) {
    const objects = this.objectCollection.objects;
    let before;
    if (index + 2 < objects.length) {
      before = objects[index + 2];
    } else {
      if (this.isLastPage()) {
        if (index === objects.length - 1) {
          // last element -> move to first
          const context: Context = {
            filter: this.objectCollection.filter,
            pagination: { page: 1, pageSize: 1 },
          };
          const pageFirst = await this.$apiv2.getListItems<BaseObject>(
            this.modelClass,
            context,
          );
          before = pageFirst[0];
        } else {
          // second last element
          // when before is null, the item is moved to the end of the list
          before = null;
        }
      } else {
        // move to next page
        const context: Context = {
          filter: this.objectCollection.filter,
          pagination: { ...this.objectCollection.pagination },
        };
        context.pagination.page += 1;
        const pageAfter = await this.$apiv2.getListItems<BaseObject>(
          this.modelClass,
          context,
        );
        before = pageAfter[index - objects.length + 2];
      }
    }
    this.insertBefore(objects[index], before);
  }

  /**
   * Whether current page is last page
   */
  isLastPage(): boolean {
    const total = this.objectCollection.info.size;
    if (!total) return false;
    const pageSize = this.objectCollection.pagination.pageSize;
    if (!pageSize) return false;
    const currentPage = this.objectCollection.pagination.page;
    return Math.ceil(total / pageSize) === currentPage;
  }

  async refreshCollection() {
    await this.objectCollection.refresh();
  }

  handleError(error) {
    this.$emit('error', error);
    this.$errorHandler.handleError(error, false);
  }

  preventRowClick(event, func) {
    event.stopPropagation();
  }

  getRowClass(row, index): string {
    let cssClass = '';
    if (this.canSelect || this.hasDetailView) {
      cssClass += ' can-select';
    }
    if (this.rowSelections[row.id] !== undefined) {
      cssClass += ' selected-row';
    }
    return cssClass;
  }

  confirmDelete(object: BaseObject) {
    this.$buefy.dialog.confirm({
      message: this.$tc('common.confirmDelete'),
      onConfirm: async () => {
        if (this.customDelete !== null) {
          await this.customDelete(object);
          return;
        } else {
          await this.deleteObject(object);
        }
      },
    });
  }

  async deleteObject(object: BaseObject) {
    let purge = false;
    if (object.object_state === LifeCycleState.Deleted) {
      purge = true;
    }
    try {
      await this.$apiv2.delete(this.modelClass, object.id, purge);
      this.$buefy.toast.open({
        message: this.$tc('common.deleteSuccess'),
        type: 'is-success',
      });
      this.$emit('base-list-event', BaseListEventType.DELETE);
    } catch (error) {
      this.handleError(error);
    }
  }

  async cellEdited(row, column, newValue) {
    try {
      if (!has(row, column.fieldName)) {
        throw new Error(`Invalid field: ${column.fieldName}`);
      }
      row[column.fieldName] = newValue;
      await this.$apiv2.update(this.modelClass, row);
      this.$buefy.toast.open({
        message: this.$tc('common.saveSuccess'),
        type: 'is-success',
      });
      this.$emit('base-list-event', BaseListEventType.UPDATE);
    } catch (error) {
      this.handleError(error);
    }
  }

  getHasPagination() {
    this.hasPagination =
      this.objectCollection.info.size >
      this.objectCollection.pagination.pageSize;
  }
}
