

















































import BaseForm from '@/components/common/BaseForm.vue';
import { authStore } from '@/store/modules/auth/auth';
import Vue from 'vue';
import Component from 'vue-class-component';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  name: 'change_password',
  components: {
    'base-form': BaseForm,
  },
})
export default class ChangePassword extends Vue {
  $refs: {
    baseForm: BaseForm;
  };
  passwords = {
    old_password: '',
    new_password1: '',
    new_password2: '',
  };
  errorMessage = '';
  redirectToPreviousPath = '';
  params: any = {};

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.redirectToPreviousPath = from.name;
      vm.params = from.params;
    });
  }

  changePassword() {
    this.$api
      .changePassword(this.passwords, authStore.profile?.id)
      .then(response => {
        this.$router.push({
          name: this.redirectToPreviousPath,
          params: { ...this.params },
        });
      })
      .catch(error => {
        this.$refs.baseForm.handleError(error);
      });
  }

  get passwordsMatch() {
    if (this.passwords.new_password1 === this.passwords.new_password2) {
      return true;
    } else {
      if (
        this.passwords.new_password1 !== '' &&
        this.passwords.new_password2 !== ''
      ) {
        this.errorMessage = this.$tc('auth.passwordNoMatch');
        return false;
      } else {
        return true;
      }
    }
  }
}
