import { render, staticRenderFns } from "./OrganisationForm.vue?vue&type=template&id=04a1ed80&scoped=true&"
import script from "./OrganisationForm.vue?vue&type=script&lang=ts&"
export * from "./OrganisationForm.vue?vue&type=script&lang=ts&"
import style0 from "./OrganisationForm.vue?vue&type=style&index=0&id=04a1ed80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a1ed80",
  null
  
)

export default component.exports