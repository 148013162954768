








































































import AttachmentList from './AttachmentList.vue';
import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { DELIVERY_METHOD_DEFAULT } from '@/models/firmware/defaults';
import { DeliveryMethod } from '@/models/firmware/models';
import { DeliveryProcedure } from '@/models/firmware/models';
import { LifeCycleState } from '@/models/core/base';
import Vue from 'vue';
import Component from 'vue-class-component';
import { deepCopy } from '@/util/util';

@Component({
  components: {
    BaseForm,
    AttachmentList,
  },
  props: {
    id: {
      required: true,
    },
    templateId: {
      default: '',
    },
  },
  data() {
    return {
      deliveryMethod: deepCopy(DELIVERY_METHOD_DEFAULT),
      baseForm: BaseForm,
      DeliveryProcedure,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeliveryMethodForm extends Vue {
  deliveryMethod: DeliveryMethod;

  mounted() {
    let id = this.$props.id;
    if (this.isFromTemplate) {
      id = this.$props.templateId;
    }
    this.$store
      .dispatch('global/getOrFetchObjectById', {
        objectType: 'delivery-method',
        id,
      })
      .then(response => {
        this.deliveryMethod = response;
        if (this.isFromTemplate) {
          this.deliveryMethod.id = '0';
          this.deliveryMethod.object_state = LifeCycleState.Draft;
        }
      });
  }

  get model() {
    return this.$store.getters['global/object']('model');
  }

  get deliveryProcedures() {
    return this.$store.getters['global/collection']('delivery-procedure');
  }

  get transformedDeliveryMethod() {
    this.deliveryMethod.model = this.model && this.model.id;
    return this.deliveryMethod;
  }

  get isFromTemplate() {
    return this.$props.templateId !== '';
  }

  get isNew() {
    return this.deliveryMethod.id === '0';
  }

  get filter() {
    return {
      delivery_method: this.$props.id,
      model: this.deliveryMethod.model,
      product: this.$store.getters['global/object']('product'),
    };
  }
}
