












































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Token, TokenCreate, TokenNew } from '@/models/core/profile';

@Component({})
export default class TokenForm extends Vue {
  @Prop({ required: true }) profile!: string;
  showNewToken = false;
  label = '';
  newKey = '';
  newHash = '';

  async createToken(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const token = await this.$apiv2.create<TokenCreate, TokenNew>(Token, {
        profile: this.profile,
        label: this.label,
      });
      this.newKey = token.key;
      this.newHash = token.truncated_key_hash;
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
    this.showNewToken = true;
  }

  async onCloseModal(): Promise<void> {
    this.label = '';
    this.newKey = '';
    this.newHash = '';
    await this.$apiv2.refreshData();
  }
}
