





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Device, DeviceSetting } from '@/models/device/models';
import { sortJsonObject } from '@/util/util';
import { PromiseConcurrencyLimiter } from '@/util/async';
import DeviceSelectionList from './DeviceSelectionList.vue';

@Component({ components: { DeviceSelectionList } })
export default class ApplySetting extends Vue {
  @Prop({ required: true }) setting!: DeviceSetting;
  @Prop({ required: true }) device!: string;
  @Prop({ required: true }) model!: string;
  @Prop({ required: true }) appId!: string;
  @Prop({ required: true }) role!: string;
  @Prop({ required: true }) mode!: 'device';

  $refs!: {
    selectionList: DeviceSelectionList;
  };

  Device = Device;
  selectedDevices: string[] = [];

  get settingString(): string {
    const value = sortJsonObject(this.setting.value);
    return JSON.stringify(value, null, 2);
  }

  onRowSelection(devices: string[]): void {
    if (devices.length > 0) {
      this.selectedDevices = Array.from(
        new Set([...this.selectedDevices, ...devices]),
      );
    }
  }

  onRowUnselect(device: string): void {
    this.selectedDevices = this.selectedDevices.filter(d => d !== device);
  }

  selectAll(): void {
    this.$refs.selectionList.selectAll();
  }

  deselectAll(): void {
    this.selectedDevices = [];
  }

  confirmApply(): void {
    this.$buefy.dialog.confirm({
      message: this.$tc('device.setting.confirmApply'),
      onConfirm: () => {
        this.applySettings();
      },
    });
  }

  async applySettings(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const limiter = new PromiseConcurrencyLimiter();
      const deviceIds = await this.getSelectedDeviceIds();
      for (const device of deviceIds) {
        await limiter.add(
          Device.setSetting(
            this.$apiv2,
            device,
            this.setting.key,
            this.setting.value,
            this.setting.kind,
          ),
        );
      }
      await limiter.awaitAllAndRethrow();
      this.$buefy.toast.open({
        message: this.$tc('common.saveSuccess'),
        type: 'is-success',
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    this.deselectAll();
    loading.close();
  }

  async getSelectedDeviceIds(): Promise<string[]> {
    return this.selectedDevices;
  }
}
