



























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import SearchField from '@/components/common/lists/SearchField.vue';
import { copyTextToClipboard } from '@/util/clipboard';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import AutoRefresh from '@/apps/device/components/AutoRefresh.vue';
import { DeviceEventLog } from '@/models/device/models';
import { DeviceEventType } from '@/models/device/interfaces';

@Component({
  components: {
    SearchField,
    AutoRefresh,
  },
})
export default class GatewaySoftwareLog extends Vue {
  @Prop({ required: true }) device: string;

  pagination = { page: 1, pageSize: 10 };
  results = [];
  loading = true;
  startDate = null;
  endDate = null;
  destroySubject = new Subject<void>();

  get total() {
    if (this.results.length === this.pagination.pageSize) {
      return (this.pagination.page + 1) * this.results.length;
    } else {
      return (
        (this.pagination.page - 1) * this.pagination.pageSize +
        this.results.length
      );
    }
  }

  mounted() {
    this.getEventResults();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getEventResults();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onPageSizeChange(pageSize) {
    this.pagination.pageSize = pageSize;
    this.getEventResults();
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.getEventResults();
  }

  onStart(date: Date) {
    this.startDate = date;
    this.pagination.page = 1;
    this.getEventResults();
  }

  onEnd(date: Date) {
    this.endDate = date;
    this.pagination.page = 1;
    this.getEventResults();
  }

  async getEventResults(showLoading = true) {
    if (showLoading) this.loading = true;

    const query = {
      device: this.device,
      page: this.pagination.page,
      page_size: this.pagination.pageSize,
      event_type: DeviceEventType.DEVICE_DATA,
      identity: 'gateway-software-log',
      start_time: this.startDate ? this.startDate.toISOString() : '',
      end_time: this.endDate ? this.endDate.toISOString() : '',
    };

    try {
      const events = await DeviceEventLog.queryEvents(query);
      this.results = events.results;
    } catch (error) {
      this.$errorHandler.handleError(error);
      this.results = [];
    }
    this.loading = false;
  }

  getAttribute(row, attribute: string): string {
    if (row.payload.attributes && row.payload.attributes[attribute]) {
      return row.payload.attributes[attribute];
    } else {
      return '';
    }
  }

  copyEventData(row) {
    copyTextToClipboard(JSON.stringify(row, null, 2));
    this.$buefy.toast.open({
      message: this.$tc('common.copiedEvent'),
      type: 'is-success',
    });
  }
}
