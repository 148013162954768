
















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'breadcrumb',
})
export default class Breadcrumb extends Vue {}
