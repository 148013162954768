







































import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { ORGANISATION_DEFAULT, Organisation } from '@/models/core/organisation';
import { Product } from '@/models/device/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { deepCopy } from '@/util/util';

@Component({
  name: 'product-form',
  components: {
    'base-form': BaseForm,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      product: Product,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class ProductForm extends Vue {
  $refs: {
    baseForm: BaseForm;
  };
  product: Product;
  loading = false;
  organisation: Organisation = deepCopy(ORGANISATION_DEFAULT);

  mounted() {
    this.loading = true;
    this.$api
      .get('product', this.$props.id)
      .then(response => {
        this.product = response;
        this.loading = false;
        return this.product.organisation;
      })
      .then(response => {
        let orgId = '';
        if (this.$props.id === '0') {
          orgId = this.$store.state.global.context.selection.organisation.id;
        } else {
          orgId = response;
        }
        this.$api.get('organisation', orgId).then(organisation => {
          this.organisation = organisation;
        });
      });
  }

  onCreateFinished(response) {
    this.$store.state.global.context.selection.product = response.data;
  }

  onUpdateFinished(response) {
    this.$store.state.global.context.selection.product = this.product;
  }

  get transformedProduct() {
    this.product.organisation = this.organisation.id;
    return this.product;
  }
}
