















import Vue from 'vue';
import Component from 'vue-class-component';
import { ObjectAuthorization } from '@/models/core/models';

@Component({})
export default class ObjectAuthorizationList extends Vue {
  ObjectAuthorization = ObjectAuthorization;

  created() {
    this.$routerHandler.updateQuery('', {
      organisation: this.$store.getters['global/organisation'].id,
    });
  }

  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    };
  }
}
