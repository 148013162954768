

































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { CollectionSubscriber, CollectionFilter } from '@/api/ApiClient';
import ProfileList from '../user/ProfileList.vue';

import { Group, GroupMembership } from '@/models/core/models';
import { Profile } from '@/models/core/profile';
import { Context } from '@/api/ApiClientV2';
import BaseListV2 from '@/components/common/BaseListV2.vue';

@Component({
  name: 'formly_profiles-select-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    ProfileList,
  },
  data() {
    return {
      Profile,
      GroupMembership,
      kind: 'human',
      organisations: this.$store.state.global.context.organisations,
      selectedOrg: this.$route.query.organisation
        ? this.$route.query.organisation
        : this.$store.getters['global/organisation'].id,
      profileFilter: null,
      groupMemberFilter: null,
      usernameSearch: null,
      groupMemberSearch: null,
      addProfileAction: {
        label: 'Add User',
        type: 'is-info',
        icon: 'mdi-account-check',
        callback: null,
      },
      removeMembershipAction: {
        label: 'Remove',
        type: 'is-danger',
        icon: 'mdi-account-remove',
        callback: null,
      },
    };
  },
})
export default class ProfilesSelectField extends Vue {
  $refs: {
    profileList: BaseListV2<Profile>;
    memberList: BaseListV2<GroupMembership>;
  };
  selectedOrg: string;
  organisations: CollectionSubscriber;
  group: Group;
  kind: string; // TODO: Create Enum For this
  profileFilter: CollectionFilter;
  groupMemberFilter: CollectionFilter;
  addProfileAction: any; // TODO: Create Interface for this
  removeMembershipAction: any; // TODO: Create Interface for this
  usernameSearch: string;
  groupMemberSearch: string;
  members: string[] = [];

  @Watch('$props.model')
  onModelChange() {
    this.init();
  }

  created() {
    this.init();
  }

  init() {
    this.filterGroupMembers();
    this.filterUsers();
    this.addProfileAction.callback = this.addProfile;
    this.removeMembershipAction.callback = this.removeMembership;
    this.getGroupMembers();
  }

  async addProfile(profile: Profile) {
    const loading = this.$buefy.loading.open({});
    try {
      const response = await this.$apiv2.create(GroupMembership, {
        group: this.$props.model.id,
        profile: profile.id,
      });
      this.$buefy.toast.open({
        message: 'Successfully added!',
        type: 'is-success',
      });
      this.$refs.profileList.clearErrors();
      this.$refs.memberList.refreshCollection();
      this.getGroupMembers();
    } catch (error) {
      this.handleError(error);
    }
    loading.close();
  }

  async removeMembership(membership: GroupMembership) {
    const loading = this.$buefy.loading.open({});
    try {
      const response = await this.$apiv2.delete(GroupMembership, membership.id);
      this.$buefy.toast.open({
        message: 'Successfully removed!',
        type: 'is-success',
      });
      this.$refs.memberList.clearErrors();
      this.$refs.memberList.refreshCollection();
      this.getGroupMembers();
    } catch (error) {
      this.handleError(error);
    }
    loading.close();
  }

  filterGroupMembers() {
    this.groupMemberFilter = {
      group: this.$props.model.id,
      search: this.groupMemberSearch,
    };
  }

  filterUsers() {
    this.profileFilter = {
      organisation: this.selectedOrg,
      kind: this.kind,
      search: this.usernameSearch,
    };
  }

  async getGroupMembers() {
    // only get group memebers when group has been created
    if (this.$props.model.id !== '0') {
      try {
        const context: Context = {
          filter: {
            group: this.$props.model.id,
          },
          pagination: {
            page: 1,
            pageSize: 500,
          },
        };
        const results = await this.$apiv2.getListItems<GroupMembership>(
          GroupMembership,
          context,
        );
        // this is used for preselected rows in profiles list
        this.members = results.map(object => object.profile);
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  handleError(error) {
    this.$errorHandler.handleError(error);
  }
}
