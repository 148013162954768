


















import Vue from 'vue';
import Component from 'vue-class-component';
import SearchField from '@/components/common/lists/SearchField.vue';
import { SearchFieldInterface, ModelClass } from '@/models/core/base';

@Component({
  props: {
    modelClass: {
      required: true,
    },
    prefix: {
      default: '',
    },
  },
  components: {
    SearchField,
  },
})
export default class SearchFields extends Vue {
  modelClass: ModelClass;

  get searchFields(): SearchFieldInterface[] {
    return this.modelClass.searchFields();
  }
}
