







































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { getDependencies } from '@/models/objectRegistry';
import { ModelClass } from '@/models/core/base';
import { Context } from '@/api/ApiClientV2';

@Component({})
export default class ParentSelectorV2 extends Vue {
  @Prop({ required: true }) modelClass: ModelClass;
  @Prop({ required: true }) required: boolean;
  @Prop({ default: false }) isForm: boolean;
  @Prop({ required: false }) customDisplayName:
    | ((option: any) => Promise<void>)
    | undefined;
  @Prop({ required: false }) filterOption:
    | ((option: any) => boolean)
    | undefined;
  @Prop({ required: false }) sortOptions:
    | ((option1: any, option2: any) => number)
    | undefined;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ required: false }) filter: { [key: string]: string };
  @Prop({ default: true }) updateOnRouteChange: boolean;

  label = '';
  value = null;
  placeholder = '';
  loading = true;
  options = [];
  prefix = '';
  ancestorObjectTypes: string[] = [];
  getListPromise: Promise<any[]>;

  @Watch('$route.query')
  onRouteChange() {
    if (this.updateOnRouteChange) {
      this.getOptions();
    }
  }

  get message() {
    if (this.required && !this.value) {
      return this.$tc('common.required');
    } else {
      return '';
    }
  }

  get isDanger() {
    if (this.required && !this.value) {
      return true;
    } else {
      return false;
    }
  }

  displayName(option): string {
    if ('name' in option) {
      return option.name;
    } else if ('handle' in option) {
      return option.handle;
    } else {
      return '';
    }
  }

  mounted() {
    this.label = this.modelClass.prettyName() || '';
    this.ancestorObjectTypes = this.modelClass.ancestors.map(
      ancestor => ancestor.modelClass.objectType,
    );
    this.getOptions();
  }

  async getOptions() {
    this.loading = true;
    this.placeholder = this.$tc('common.loading') + '...';

    let deselectedText = `- ${this.$tc('common.noFilter')} -`;
    if (this.isForm) {
      deselectedText = `- ${this.$tc('common.noSelection')} -`;
    }
    let filter = this.$routerHandler.query(this.prefix);
    // remove filters that are not ancestors
    Object.keys(filter).forEach((key: string) => {
      if (!this.ancestorObjectTypes.find(objectType => key === objectType)) {
        delete filter[key];
      }
    });
    filter.organisation = this.$store.getters['global/organisation'].id;
    if (this.filter) {
      filter = {
        ...filter,
        ...this.filter,
      };
    }
    const context: Context = {
      filter: filter,
      pagination: {
        page: 1,
        pageSize: 50,
      },
    };
    // empty options already here to not show old options
    this.options = [];
    try {
      // avoid race condition by awating previous api calls
      // TODO: a better way to solve this would be to cancel the previous api call, see #383
      if (this.getListPromise) {
        await this.getListPromise;
      }
      this.getListPromise = this.$apiv2.getListItems<any>(
        this.modelClass,
        context,
      );
      const results = await this.getListPromise;
      // empty again to avoid duplicates if getOptions is called multiple times
      this.options = [];
      if (!this.required) {
        this.options.push({
          name: deselectedText,
        });
      }
      this.options = this.options.concat(results);
      if (this.customDisplayName) {
        const promises = [];
        this.options.forEach(option => {
          promises.push(this.customDisplayName(option));
        });
        await Promise.all(promises);
      } else {
        this.options.forEach(option => {
          option._displayName = this.displayName(option);
        });
      }
      if (this.filterOption) {
        this.options = this.options.filter(this.filterOption);
      }
      if (this.sortOptions) {
        this.options.sort(this.sortOptions);
      }
      if (this.options.length === 0) {
        this.placeholder = `${this.$tc('common.noPrefix')} ${
          this.label
        } ${this.$tc('common.found')}`;
      } else {
        if (this.isForm) {
          this.placeholder = `${this.label} ${this.$tc('common.select')}`;
        } else {
          this.placeholder = `${this.$tc('common.filterBy')} ${this.label}`;
        }
      }
      // select option in url
      this.value = this.$routerHandler.query(this.prefix)[
        // TODO: properly fix dash vs underscore
        this.modelClass.objectType.replace('-', '_')
      ];
      // if only one option available, select this option
      if (this.options.length === 1) {
        this.value = this.options[0].id;
        this.applyFilter(this.value);
      }
    } catch (err) {
      this.options = [];
      this.placeholder = `${this.$tc('common.noPrefix')} ${
        this.label
      } ${this.$tc('common.found')}`;
      console.log(err);
    }
    this.loading = false;
  }

  applyFilter(id: string) {
    // only apply if filter changed
    if (
      id !== null &&
      this.$routerHandler.query(this.prefix)[
        this.modelClass.objectType.replace('-', '_')
      ] !== id
    ) {
      // disable filter input function when navigation is in progress
      // otherwise the change of state during navigation will trigger
      // the input function a second time
      if (!this.$store.getters['global/navigationIsActive']) {
        const redirect = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            [this.modelClass.objectType.replace('-', '_')]: id,
          },
        };

        // clear selected page when changing a filter
        delete redirect.query.page;

        for (const item of getDependencies(this.$props.modelClass.objectType)) {
          // delete depending object query parameters
          // e.g. when product changes, model must be cleared
          delete redirect.query[item];
        }
        this.$routerHandler.push(redirect);
        // add selected object to store
        this.$store.dispatch('global/selectObjectById', {
          objectType: this.modelClass.objectType,
          id,
        });
      }
    }
  }
}
