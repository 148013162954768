











import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'formly_markdown-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class MarkdownField extends Vue {}
