















import { Vue, Component } from 'vue-property-decorator';
import { ClientApp } from '@/models/client/models';

@Component({})
export default class ClientAppList extends Vue {
  ClientApp = ClientApp;

  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    };
  }
}
