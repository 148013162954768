





























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Compact } from 'vue-color';

@Component({
  name: 'formly_color-field',
  props: ['form', 'field', 'model', 'to'],
  components: {
    Compact,
  },
  data() {
    return {
      showColorPicker: false,
    };
  },
})
export default class ColorField extends Vue {
  showColorPicker: boolean;

  onInput(value) {
    this.$props.model[this.$props.field.key] = value.hex;
    this.showColorPicker = false;
  }

  clear() {
    this.$props.model[this.$props.field.key] = undefined;
  }
}
