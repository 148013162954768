




















import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-generate-leitwert-firmware-info',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class GenerateLeitwertFirmwareInfo extends Vue {
  plugin: DeliveryProcedurePlugin;
  fwiTypes = ['fw-info-v2-pad32'];

  mounted() {
    this.plugin = this.$props.plugin;
  }
}
