












import Vue from 'vue';
import Component from 'vue-class-component';

import { Group } from '@/models/core/models';

@Component({})
export default class GroupList extends Vue {
  Group = Group;
  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
      order_by: 'name',
    };
  }
}
