































import { globalStore } from '@/store/modules/global';
import { NavLink } from '@/store/modules/interfaces';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NavLinks extends Vue {
  get navLinks(): NavLink[] {
    return globalStore.navLinks;
  }
}
