













































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'formly_rotation-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class RotationField extends Vue {
  $refs: {
    input: any;
  };
  get step() {
    return this.$props.field.properties.step;
  }

  get minValue() {
    return this.$props.field.properties.min;
  }

  get maxValue() {
    return this.$props.field.properties.max;
  }

  checkValid(value) {
    if (this.maxValue && value > this.maxValue) {
      this.$props.form.$errors[
        this.$props.field.key
      ] = `Max Value is ${this.maxValue}`;
    } else if (this.minValue && value < this.minValue) {
      this.$props.form.$errors[
        this.$props.field.key
      ] = `Min Value is ${this.minValue}`;
    } else {
      this.$props.form.$errors[this.$props.field.key] = {};
    }
  }

  mounted() {
    this.checkValid(this.$props.model[this.$props.field.key]);
    this.$refs.input.checkHtml5Validity();
  }

  onInput(value) {
    value = parseInt(value);
    this.$props.model[this.$props.field.key] = value;
    this.checkValid(value);
  }

  setAngle(value: number) {
    this.$props.model[this.$props.field.key] = value;
  }
}
