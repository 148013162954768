




















































import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { PRODUCT_DEFAULT } from '@/models/device/defaults';
import { Model, Product } from '@/models/device/models';
import { LifeCycleState } from '@/models/core/base';
import Vue from 'vue';
import Component from 'vue-class-component';

import ParentSelectors from '@/components/common/ParentSelectors.vue';
import ObjectBreadcrumbs from '@/components/common/ObjectBreadcrumbs.vue';

@Component({
  name: 'model-form',
  components: {
    BaseForm,
    ParentSelectors,
    ObjectBreadcrumbs,
  },
  props: {
    id: {
      required: true,
    },
    templateId: {
      default: '',
    },
  },
  data() {
    return {
      model: Model,
      loading: true,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class ModelForm extends Vue {
  $refs: {
    baseForm: BaseForm;
  };
  model: Model;
  loading = false;

  mounted() {
    this.loading = true;
    let id = this.$props.id;
    if (this.isFromTemplate) {
      id = this.$props.templateId;
    }
    this.$api
      .get('model', id)
      .then(response => {
        this.model = response;
        if (this.isFromTemplate) {
          this.model.id = '0';
          this.model.object_state = LifeCycleState.Draft;
          if (this.model.system_item_handle === null) {
            delete this.model.system_item_handle;
          }
        }
        this.loading = false;
        return this.model.product;
      })
      .then(response => {
        let productId = '';
        if (
          this.model.id === '0' &&
          this.$store.state.global.context.selection.product
        ) {
          productId = this.$store.state.global.context.selection.product.id;
        } else {
          productId = response;
        }
      });
    this.loading = false;
  }

  get product() {
    return this.$store.getters['global/object']('product');
  }

  set handleValue(newVal) {
    this.model.handle = newVal;
    if (newVal === '') {
      delete this.model.handle;
    }
  }

  get handleValue() {
    return this.model.handle;
  }

  get isFromTemplate() {
    return this.$props.templateId !== '';
  }

  get isNew() {
    return this.model.id === '0';
  }

  get isDraft() {
    return this.model.object_state === LifeCycleState.Draft;
  }

  get transformedModel() {
    if (this.model.handle === '') {
      this.model.handle = null;
    }
    this.model.product = this.product && this.product.id;
    return this.model;
  }
}
