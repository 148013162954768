












































import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-load-const',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class LoadConst extends Vue {
  plugin: DeliveryProcedurePlugin;
  payloads = [];

  mounted() {
    this.plugin = this.$props.plugin;
    for (const key in this.plugin.config['output_payloads']) {
      const payload = this.plugin.config['output_payloads'][key];
      this.payloads.push({ key: key, value: payload.value });
    }
  }

  addPayload() {
    const newPayload = { key: '', value: '' };
    this.payloads.push(newPayload);
  }

  removePayload(index) {
    this.payloads.splice(index, 1);
  }

  getLabel(index, type) {
    if (index === 0) {
      if (type === 0) {
        return 'Key';
      } else if (type === 1) {
        return 'Value';
      } else if (type === 2) {
        return 'Remove';
      }
    }
  }
}
