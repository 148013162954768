






















































































































































































































































































































import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import ConvertPayloads from './plugins/ConvertPayloads.vue';
import { DELIVERY_PROCEDURE_STAGES } from './deliveryProcedures';
import DownloadLocal from './plugins/DownloadLocal.vue';
import DurationInput from '@/components/common/DurationInput.vue';
import EncodeIHex32 from './plugins/EncodeIHex32.vue';
import EncryptLWEFWV2 from './plugins/EncryptLWEFWV2.vue';
import GenerateKey from './plugins/GenerateKey.vue';
import GenerateLeitwertFirmwareInfo from './plugins/GenerateLeitwertFirmwareInfo.vue';
import LoadAttachment from './plugins/LoadAttachment.vue';
import LoadConst from './plugins/LoadConst.vue';
import LoadKey from './plugins/LoadKey.vue';
import { LifeCycleState } from '@/models/core/base';
import {
  DELIVERY_PROCEDURE_STAGE_NAMES,
  DeliveryProcedure,
  DurationType,
} from '@/models/firmware/models';
import ParseIHex32 from './plugins/ParseIHex32.vue';
import PatchFirmwareImage from './plugins/PatchFirmwareImage.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import YamlPlugin from './plugins/YamlPlugin.vue';
import { deepCopy } from '@/util/util';

@Component({
  name: 'delivery-procedure-form',
  components: {
    'base-form': BaseForm,
    'builtin-download-local': DownloadLocal,
    'builtin-encode-ihex32': EncodeIHex32,
    'builtin-patch-firmware-image': PatchFirmwareImage,
    'builtin-convert-payloads': ConvertPayloads,
    'builtin-generate-leitwert-firmware-info': GenerateLeitwertFirmwareInfo,
    'builtin-load-key': LoadKey,
    'builtin-parse-ihex32': ParseIHex32,
    'builtin-load-const': LoadConst,
    'builtin-load-attachment': LoadAttachment,
    'builtin-encrypt-lwefwv2': EncryptLWEFWV2,
    'builtin-generate-key': GenerateKey,
    'yaml-plugin': YamlPlugin,
    'duration-input': DurationInput,
  },
  props: {
    id: {
      required: true,
    },
    templateId: {
      default: '',
    },
  },
  data() {
    return {
      deliveryProcedure: DeliveryProcedure,
      stageNames: DELIVERY_PROCEDURE_STAGE_NAMES,
      stagesJSONText: '',
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeliveryProcedureForm extends Vue {
  $refs: {
    baseForm: BaseForm;
  };
  deliveryProcedure: DeliveryProcedure;
  parameterFields = [];
  selectedPluginId = '';
  loading = true;
  stagesText: string;
  stagesJSONText: string;

  mounted() {
    this.loading = true;
    let id = this.$props.id;
    if (this.isFromTemplate) {
      id = this.$props.templateId;
    }
    this.$api.get('delivery-procedure', id).then(response => {
      this.deliveryProcedure = response;
      if (this.isFromTemplate) {
        this.deliveryProcedure.id = '0';
        this.deliveryProcedure.object_state = LifeCycleState.Draft;
      }
      this.deliveryProcedure.delivery_method =
        this.deliveryMethod && this.deliveryMethod.id;
      this.stagesJSONText = JSON.stringify(response.stages);
      for (const key in this.deliveryProcedure.parameters) {
        this.parameterFields.push({
          name: key,
          value: this.deliveryProcedure.parameters[key].description,
        });
      }
      this.loading = false;
      return this.deliveryProcedure.delivery_method;
    });
  }

  updateDuration(newDurationType: DurationType) {
    if (newDurationType.durationLabel === 'ready') {
      this.deliveryProcedure.update_options.expiration_policy.ready =
        newDurationType.durationSeconds;
    } else if (newDurationType.durationLabel === 'up_to_date') {
      this.deliveryProcedure.update_options.expiration_policy.up_to_date =
        newDurationType.durationSeconds;
    } else if (newDurationType.durationLabel === 'success') {
      this.deliveryProcedure.update_options.expiration_policy.success =
        newDurationType.durationSeconds;
    } else if (newDurationType.durationLabel === 'fail') {
      this.deliveryProcedure.update_options.expiration_policy.fail =
        newDurationType.durationSeconds;
    } else if (newDurationType.durationLabel === 'unconfirmed') {
      this.deliveryProcedure.update_options.expiration_policy.unconfirmed =
        newDurationType.durationSeconds;
    } else if (newDurationType.durationLabel === 'confirm_timeout') {
      this.deliveryProcedure.update_options.confirm_timeout =
        newDurationType.durationSeconds;
    } else if (newDurationType.durationLabel === 'retry_time') {
      this.deliveryProcedure.update_options.concurrency_limit_retry_after =
        newDurationType.durationSeconds;
    } else {
      throw new RangeError('Duration label not handled.');
    }
  }

  editorTextChanged(textValue) {
    this.deliveryProcedure.config_text_buffer = textValue;
  }

  updateJSONObject(newStagesJSONObj) {
    this.deliveryProcedure.stages = newStagesJSONObj;
  }

  addParameterField() {
    this.parameterFields.push({ name: '', value: '' });
  }

  removeParameterField(index) {
    this.parameterFields.splice(index, 1);
  }

  addStage(pluginId: string) {
    const defaultConfig = deepCopy(DELIVERY_PROCEDURE_STAGES[pluginId]);
    defaultConfig.isCardOpen = true;
    this.deliveryProcedure.stages.push(defaultConfig);
  }

  removeStage(index) {
    this.deliveryProcedure.stages.splice(index, 1);
  }

  get stages() {
    return this.deliveryProcedure.stages;
  }

  moveUpStage(index) {
    this.deliveryProcedure.stages = this.move(
      this.deliveryProcedure.stages,
      index,
      index - 1,
    );
  }

  moveDownStage(index) {
    this.deliveryProcedure.stages = this.move(
      this.deliveryProcedure.stages,
      index,
      index + 1,
    );
  }

  move(arr, oldIndex, newIndex) {
    if (oldIndex < 0) {
      return arr;
    }
    if (newIndex < 0) {
      this.$buefy.toast.open({
        message: `Can't move further up.`,
        type: 'is-danger',
      });
      return arr;
    }
    if (newIndex >= arr.length) {
      this.$buefy.toast.open({
        message: `Can't move further down.`,
        type: 'is-danger',
      });
      return arr;
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  get deliveryMethod() {
    return this.$store.getters['global/object']('delivery-method');
  }

  get deliveryProcedureTransformed() {
    this.deliveryProcedure.parameters = {};
    this.parameterFields.forEach(param => {
      this.deliveryProcedure.parameters[param.name] = {
        description: param.value,
      };
    });
    this.deliveryProcedure.delivery_method =
      this.deliveryMethod && this.deliveryMethod.id;

    if (this.deliveryProcedure.update_options.enable_confirm_step === false) {
      this.deliveryProcedure.update_options.expiration_policy.unconfirmed =
        null;
    }
    return this.deliveryProcedure;
  }

  get availableStages() {
    const plugins = [];
    for (const key in DELIVERY_PROCEDURE_STAGES) {
      plugins.push(key);
    }
    return plugins;
  }

  get isFromTemplate() {
    return this.$props.templateId !== '';
  }
}
