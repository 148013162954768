


























import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-load-key',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class LoadKey extends Vue {
  plugin: DeliveryProcedurePlugin;
  keyKindOption = ['secure-random-256'];

  mounted() {
    this.plugin = this.$props.plugin;
  }
}
