













import Vue from 'vue';
import Component from 'vue-class-component';
import { DeliveryMethod } from '@/models/firmware/models';

@Component({})
export default class DeliveryMethodList extends Vue {
  DeliveryMethod = DeliveryMethod;
}
