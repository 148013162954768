
























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { ORGANISATION_DEFAULT, Organisation } from '@/models/core/organisation';
import { ClientApp, ClientAppSettingKind } from '@/models/client/models';
import { clientAppList } from '@/apps/clientAppRegistry';
import ClientAppSettings from './ClientAppSettings.vue';
import { deepCopy } from '@/util/util';
import { CLIENT_APP_DEFAULT } from '@/models/client/defaults';
import { adminRouteName } from '@/apps/admin/app';
import { Location } from 'vue-router';

@Component({
  components: {
    BaseForm,
    ClientAppSettings,
  },
  mixins: [BeforeLeaveGuard],
})
export default class ClientAppForm extends Vue {
  @Prop({ required: true }) id!: string;
  $refs!: {
    baseForm: BaseForm;
  };
  clientApp: ClientApp = deepCopy(CLIENT_APP_DEFAULT);
  loading = true;
  organisation: Organisation = deepCopy(ORGANISATION_DEFAULT);
  clientAppList = clientAppList;
  ClientAppSettingKind = ClientAppSettingKind;

  settingKindCreateRouteName = adminRouteName('client-app-setting-kind-create');
  settingKindDetailRouteName = adminRouteName('client-app-setting-kind-detail');

  get settingKindCreateRouteExtras(): Location {
    return {
      params: {
        client_app: this.id,
      },
    };
  }

  get completedLocation() {
    if (this.$route.name === 'bootstrap-client-app-create') {
      return {
        name: 'organisation',
      };
    } else {
      return null;
    }
  }

  get isNew() {
    return this.id === '0';
  }

  get transformedClientApp() {
    this.clientApp.organisation = this.organisation.id;
    return this.clientApp;
  }

  get settingKindFilter() {
    return {
      client_app: this.id,
    };
  }

  async mounted() {
    try {
      this.clientApp = await this.$apiv2.get(ClientApp, this.id);
      if (this.clientApp?.organisation) {
        this.organisation = await this.$apiv2.get(
          Organisation,
          this.clientApp.organisation,
        );
      } else {
        this.organisation = await this.$apiv2.get(
          Organisation,
          this.$store.getters['global/object']('organisation').id,
        );
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    this.loading = false;
  }
}
