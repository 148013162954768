























































import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-convert-payloads',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class ConvertPayloads extends Vue {
  plugin: DeliveryProcedurePlugin;

  mounted() {
    this.plugin = this.$props.plugin;
  }

  addPayload() {
    this.plugin.config['payloads'].push({
      source: '',
      convert: '',
      destination: '',
    });
  }

  removePayload(index) {
    this.plugin.config['payloads'].splice(index, 1);
  }

  getLabel(index, type) {
    if (index === 0) {
      if (type === 0) {
        return 'The source payload';
      } else if (type === 1) {
        return 'Conversion function';
      } else if (type === 2) {
        return 'Destination payload';
      } else if (type === 3) {
        return 'Remove';
      }
    }
  }
}
