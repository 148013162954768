


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { DELIVERY_ATTACHMENT_DEFAULT } from '@/models/firmware/defaults';
import { DeliveryAttachment } from '@/models/firmware/models';
import { deepCopy } from '@/util/util';
import BaseListV2 from '@/components/common/BaseListV2.vue';

@Component({})
export default class AttachmentList extends Vue {
  @Prop({ required: true }) delivery_method: string;

  DeliveryAttachment = DeliveryAttachment;
  deliveryAttachment: DeliveryAttachment = deepCopy(
    DELIVERY_ATTACHMENT_DEFAULT,
  );
  file: File | null = null;
  $refs: {
    baseList: BaseListV2<DeliveryAttachment>;
  };

  get filter() {
    return {
      delivery_method: this.delivery_method,
    };
  }

  async createAttachment() {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      if (this.file) {
        const formData = new FormData();
        formData.append('content', this.file, this.file.name);
        const response = await this.$api.customCreate(
          'delivery-attachment/upload',
          formData,
        );
        this.deliveryAttachment.attachment = response.data.id;
        this.deliveryAttachment.create_time = new Date();
        this.deliveryAttachment.delivery_method = this.delivery_method;
        await this.$api.create('delivery-attachment', this.deliveryAttachment);
        this.$refs.baseList.refreshCollection();
        this.$buefy.toast.open({
          message: 'Successfully saved!',
          type: 'is-success',
        });
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }
}
