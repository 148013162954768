


































import { CommonContext } from '@/store/GlobalStore';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations, mapState } from 'vuex';

@Component({
  name: 'client-app-selector',
  computed: {
    ...mapState('global', ['context']),
  },
})
export default class ClientAppSelector extends Vue {
  context: CommonContext;

  selectClientApp(app) {
    // when the state changes the value of the dropdown is updated which
    // triggers the @input handler which is not wanted
    /*
      if (organisation && this.context.selection.organisation !== organisation) {
        this.$router.push({
          name: 'organisation',
          params: {
            org_slug: organisation.slug,
          }
        })
      }
      */
  }
}
