
























import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'formly_label-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class LabelField extends Vue {}
