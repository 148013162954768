




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { isMobile, setDocumentTitle } from '@/util/util';

@Component({
  name: 'main-menu-entry',
  props: {
    iconName: {
      required: true,
    },
    text: {
      required: true,
    },
    location: {
      required: true,
    },
    isChild: {
      required: false,
    },
  },
})
export default class MainMenuEntry extends Vue {
  get iconNameClass() {
    if (this.$props.iconName.startsWith('fa')) {
      return 'fa ' + this.$props.iconName;
    }
    if (this.$props.isChild) {
      return 'mdi mdi-18px ' + this.$props.iconName;
    } else {
      return 'mdi mdi-24px ' + this.$props.iconName;
    }
  }

  get isLinkActive(): boolean {
    if (this.$route.name && this.$route.name === this.$props.location.name) {
      setDocumentTitle(`${this.$props.text} - Device Management Server`);
      return true;
    } else if (
      this.$route.matched.some(match => {
        return match.name && match.name === this.$props.location.name;
      })
    ) {
      setDocumentTitle(`${this.$props.text} - Device Management Server`);
      return true;
    }
    return false;
  }

  clickLink() {
    if (isMobile()) {
      // hide menu
      this.$store.commit('global/setNavigationCollapsed', { value: true });
      this.$store.commit('global/setReaderMode', { value: true });
    }
  }
}
