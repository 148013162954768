




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { setDocumentTitle } from './util/util';

@Component({
  name: 'error_page',
})
export default class ErrorPage extends Vue {
  created(): void {
    const document: Document = window.document;
    const favicon: HTMLElement & { href?: string } =
      document.getElementById('favicon');
    setDocumentTitle('Device Management Server');
    favicon.href = '/img/favicon.ico';
  }
}
