import { render, staticRenderFns } from "./ObjectBreadcrumbs.vue?vue&type=template&id=1423c418&scoped=true&"
import script from "./ObjectBreadcrumbs.vue?vue&type=script&lang=ts&"
export * from "./ObjectBreadcrumbs.vue?vue&type=script&lang=ts&"
import style0 from "./ObjectBreadcrumbs.vue?vue&type=style&index=0&id=1423c418&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1423c418",
  null
  
)

export default component.exports