






















import Vue from 'vue';
import Component from 'vue-class-component';
import { loadLanguageAsync } from '@/lang/setup';
import { LocaleOption, DMS_DEFAULT_LOCALE } from '@/env';
import { getItem, setItem } from '@/util/storage';

@Component({})
export default class LanguageSwitcher extends Vue {
  locale: LocaleOption = DMS_DEFAULT_LOCALE;
  key = 'DMS_LOCALE';

  async mounted() {
    const lang = await getItem<LocaleOption>(this.key);
    if (lang) {
      this.setLocale(lang);
    }
  }

  async setLocale(lang: LocaleOption) {
    this.locale = lang;
    await loadLanguageAsync(lang);
    await setItem<LocaleOption>(this.key, lang);
  }
}
