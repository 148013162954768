





















import Vue from 'vue';
import Component from 'vue-class-component';

import { adminRouteName } from '../app';
import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';

@Component({
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-chart-pie',
        text: 'Dashboard',
        location: { name: adminRouteName('dashboard') },
      },
      {
        iconName: 'mdi-domain',
        text: this.$tc('admin.organisation.prettyName', 2),
        location: { name: adminRouteName('organisation-root') },
      },
      {
        iconName: 'mdi-account',
        text: this.$tc('admin.user.prettyName', 2),
        location: { name: adminRouteName('profile-root') },
      },
      {
        iconName: 'mdi-google-circles-communities',
        text: this.$tc('admin.role.prettyName', 2),
        location: { name: adminRouteName('role-root') },
      },
      {
        iconName: 'mdi-account-group',
        text: this.$tc('admin.group.prettyName', 2),
        location: { name: adminRouteName('group-root') },
      },
      {
        iconName: 'mdi-lock-outline',
        text: this.$tc('admin.objectauthorization.prettyName', 2),
        location: { name: adminRouteName('object-authorization-root') },
      },
      {
        iconName: 'mdi-puzzle',
        text: this.$tc('client.clientapp.prettyName', 2),
        location: { name: adminRouteName('client-app-root') },
      },
    ];
  }
}
