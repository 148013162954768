



















import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-encode-ihex32',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class EncodeIHex32 extends Vue {
  plugin: DeliveryProcedurePlugin;

  mounted() {
    this.plugin = this.$props.plugin;
  }
}
